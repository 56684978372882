<template>
  <path d="M1.66659 9.66671V11C1.66659 12.4734 2.86059 13.6667 4.33325 13.6667H10.9999C12.4733 13.6667 13.6666 12.4734 13.6666 11C13.6666 8.78071 13.6666 5.22004 13.6666 3.00004C13.6666 1.52737 12.4733 0.333374 10.9999 0.333374C9.09392 0.333374 6.23992 0.333374 4.33325 0.333374C2.86059 0.333374 1.66659 1.52737 1.66659 3.00004V4.33337C1.66659 4.70137 1.96525 5.00004 2.33325 5.00004C2.70125 5.00004 2.99992 4.70137 2.99992 4.33337C2.99992 4.33337 2.99992 3.70871 2.99992 3.00004C2.99992 2.26404 3.59725 1.66671 4.33325 1.66671H10.9999C11.7366 1.66671 12.3333 2.26404 12.3333 3.00004V11C12.3333 11.7367 11.7366 12.3334 10.9999 12.3334C9.09392 12.3334 6.23992 12.3334 4.33325 12.3334C3.59725 12.3334 2.99992 11.7367 2.99992 11C2.99992 10.292 2.99992 9.66671 2.99992 9.66671C2.99992 9.29937 2.70125 9.00004 2.33325 9.00004C1.96525 9.00004 1.66659 9.29937 1.66659 9.66671ZM6.72392 6.33337L5.86192 5.47204C5.60192 5.21137 5.60192 4.78937 5.86192 4.52871C6.12258 4.26871 6.54459 4.26871 6.80459 4.52871L8.80459 6.52871C9.06525 6.78937 9.06525 7.21137 8.80459 7.47204L6.80459 9.47204C6.54459 9.73204 6.12258 9.73204 5.86192 9.47204C5.60192 9.21137 5.60192 8.78937 5.86192 8.52871L6.72392 7.66671H0.999919C0.631919 7.66671 0.333252 7.36804 0.333252 7.00004C0.333252 6.63271 0.631919 6.33337 0.999919 6.33337H6.72392Z" />
</template>

<script>
export default {
  name: 'IconInsertLog'
}
</script>

<style scoped>

</style>
