<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th style="width: 200px;">
            Event logs
          </th>
          <th class="has-text-right">
            <a
              class="has-text-white"
              @click="showInsertLogModal()">
              <IconBase
                width="13"
                height="13"
                icon-name="edit">
                <IconInsertLog />
              </IconBase>
              {{ $t('views.MyAccount.Insert Log') }}</a>
          </th>
        </tr>
      </thead>
      <tbody v-if="history.length > 0">
        <tr
          v-for="(ev, idx) in history"
          :key="idx"
          @click="handleHistorySelected(ev)">
          <td>
            <p>{{ `${getEventName(ev.eventName, ev.orderStatus, ev)} - Reference #${orderReference} ` }}</p>
            <p class="has-text-grey-4 break-word">
              {{ getEventDescription(ev) }}
            </p>
          </td>
          <td
            class="has-text-right"
            style="width: 150px;">
            {{ ev.timestamp | date('DD MMM, YYYY hh:mm:ss') }}
          </td>
        </tr>
      </tbody>
    </table>
    <PpModal
      v-model="modalVisibility"
      :is-loading="false"
      is-click-outside-close
      @closed="handleCloseModal">
      <div class="is-pulled-right">
        <i
          class="el-icon-close"
          style="font-size: 24px;"
          @click="modalVisibility=false" />
      </div>
      <div
        v-if="selectedHistoryEventData"
        class="box">
        <div class="box-header">
          <span class="has-text-weight-semibold has-text-primary">Event data - {{ selectedHistoryEventData.type }} ({{ selectedHistoryEventData.eventName }})</span>
        </div>
        <div
          v-if="selectedHistoryEventData.type === 'api_call'"
          class="box-content">
          <strong>Request</strong>
          <pre>{{ selectedHistoryEventData.data.request }}</pre>
          <br>
          <strong>Response</strong>
          <pre>{{ selectedHistoryEventData.data.response }}</pre>
        </div>
        <div
          v-else
          class="box-content">
          <pre>{{ selectedHistoryEventData.data }}</pre>
        </div>
      </div>
    </PpModal>
  </div>
</template>

<script>
import { getOrderEventName, getOrderEventDescription } from '@/plugins/util'
import IconInsertLog from '@/components/icons/IconInsertLog'
export default {
  name: 'OrderHistory',
  components: {
    IconInsertLog
  },
  props: {
    history: {
      type: Array,
      required: true
    },
    orderReference: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedHistoryEventData: null,
      modalVisibility: false,
      displayNames: []
    }
  },
  methods: {
    showInsertLogModal () {
      this.$emit('clicked-insert-log')
    },
    getEventName (eventName, orderStatus, event) {
      return getOrderEventName(eventName, orderStatus, event)
    },
    getEventDescription (event) {
      const eventName = event.eventName

      let description = getOrderEventDescription(eventName, event.orderStatus)

      if (eventName === 'payment.refused') {
        description += ` Reason: “${event.data.response.refusalReason}”`
      }

      if (eventName === 'order.updatedManually') {
        description = `Log by ${event.data.adminUser.displayName}`
      }

      if (eventName === 'order.cancel_request') {
        description = 'Order has been cancelled by an user'
      }

      return description
    },
    handleHistorySelected (ev) {
      this.selectedHistoryEventData = ev
      this.modalVisibility = true
    },
    handleCloseModal () {
      this.selectedHistoryEventData = null
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .pp-modal--content {
  max-width: 100%;
}
pre {
  width: 100%;
}
tr td {
  cursor: pointer;
}

tr:hover td {
  background-color: #f5f7fa;
}
</style>
