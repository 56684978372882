<template>
  <el-form
    ref="searchBookingsForm"
    :model="localForm"
    :rules="rules"
    class="columns is-multiline search-bookings-form"
    label-position="top"
    @submit.native.prevent="">
    <div class="column is-6">
      <div class="columns">
        <el-form-item
          :label="$t('views.MyBookings.form.dateRange.label')"
          class="column is-4 hide-border-radius-right"
          prop="dateRangeType">
          <el-select
            v-model="localForm.dateRangeType"
            :placeholder="$t('views.MyBookings.form.dateRange.placeholder')"
            @change="handleSearch">
            <el-option
              v-for="item in dateRageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item
          class="column is-8 hide-border-radius-left"
          label=" "
          prop="dateRange">
          <DatePicker
            v-model="localForm.dateRange"
            :hovering-tooltip="false"
            :min-nights="0"
            :no-min-date="true"
            :show-year="true"
            :display-clear-button="true"
            @input="handleSearch" />
        </el-form-item>
      </div>
    </div>
    <el-form-item
      label=" "
      class="column is-2"
      prop="orderStatuses">
      <el-select
        v-model="localForm.orderStatuses"
        :placeholder="$t('views.MyBookings.form.status.placeholder')"
        multiple
        collapse-tags
        @change="statusFilterChange">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item
      label=" "
      class="column is-2"
      prop="orderReference">
      <el-input
        v-model="localForm.orderReference"
        placeholder="Booking ID"
        @input="delaySearch" />
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="hotelName">
      <el-input
        v-model="localForm.hotelName"
        :placeholder="$t('views.MyBookings.form.hotelName.label')"
        @input="delaySearch" />
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="guestName">
      <el-input
        v-model="localForm.guestName"
        placeholder="Primary guest"
        @input="delaySearch" />
    </el-form-item>
    <el-form-item
      class="column is-2"
      prop="clients">
      <el-select
        v-model="localForm.clients"
        class="clientTag"
        placeholder="Client"
        filterable
        multiple
        collapse-tags
        @change="delaySearch">
        <el-option
          v-for="item in agentOptions"
          :key="item.appCode"
          :label="item.companyName"
          :value="item.appCode" />
      </el-select>
    </el-form-item>
    <el-form-item
      class="column is-2"
      prop="agentReference">
      <el-input
        v-model="localForm.agentReference"
        placeholder="Agent reference code"
        @input="delaySearch" />
    </el-form-item>

    <div class="column  is-2">
      <!--      <el-button-->
      <!--          :loading="isFetching"-->
      <!--          class="button-secondary" style="width: 120px;"-->
      <!--          @click="handleSearch">{{ $t('views.share.search') }}-->
      <!--      </el-button>-->
      <el-button
        v-if="hasSearchParams"
        :disabled="isFetching"
        class="button-secondary-outline"
        @click="clear">
        Clear ALL
      </el-button>
    </div>
    <!-- <div class="column is-12 has-text-right">
      <el-button
        size="mini"
        type="text"
        class="has-text-white has-text-weight-normal"
        @click="clear">{{ $t('components.FiltersPriceRange.clear') }}</el-button>
      <el-button
        class="button-secondary button-155"
        @click="handleSearch">
        {{ $t('views.share.search') }}
      </el-button>
    </div> -->
  </el-form>
</template>

<script>
import DatePicker from '@/components/DatePicker/index'
import debounce from 'lodash/debounce'

export default {
  name: 'SearchBookingsForm',
  components: { DatePicker },
  props: {
    isFetching: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localForm: {
        dateRangeType: 'checkin-date',
        dateRange: {
          checkInDate: '',
          checkOutDate: ''
        },
        orderReference: '',
        guestName: '',
        clients: [],
        orderStatuses: [],
        agentReference: ''
      },
      agentOptions: [],
      dateRageOptions: [
        {
          value: 'checkin-date',
          label: this.$t('views.CheckoutFormSummary.check-in-date')
        },
        {
          value: 'checkout-date',
          label: this.$t('views.CheckoutFormSummary.check-out-date')
        },
        {
          value: 'booking-date',
          label: this.$t('views.MyBookings.booking-date')
        }
      ],
      agentNameOptions: [
        {
          value: 'Agent A',
          label: 'Agent A'
        },
        {
          value: 'Agent B',
          label: 'Agent B'
        }
      ],
      statusOptions: [
        {
          value: undefined,
          label: this.$t('views.MyBookings.status.all')
        },
        {
          value: 'CONFIRMED',
          label: this.$t('views.MyBookings.status.confirmed')
        },
        {
          value: 'ON_HOLD',
          label: this.$t('views.MyBookings.status.on-hold')
        },
        {
          value: 'PROCESSING',
          label: this.$t('views.MyBookings.status.processing')
        },
        {
          value: 'PENDING',
          label: this.$t('views.MyBookings.status.pending')
        }, {
          value: 'AWAITING_CONFIRMATION',
          label: this.$t('views.MyBookings.status.awaiting-confirmation')
        },
        {
          value: 'PAYMENT_PENDING',
          label: this.$t('views.MyBookings.status.payment-pending')
        },
        {
          value: 'CANCELLED',
          label: this.$t('views.MyBookings.status.cancelled')
        },
        {
          value: 'FAILURE',
          label: this.$t('views.MyBookings.status.booking-failure')
        },
        {
          value: 'REFUND_IN_PROGRESS',
          label: this.$t('views.MyBookings.status.refund-in-progress')
        },
        {
          value: 'REFUND_FAILURE',
          label: this.$t('views.MyBookings.status.refund-failure')
        }
      ],
      rules: {
        guestName: [
          { type: 'string', min: 2, message: 'Type at least 2 characters to search.', trigger: [ 'change', 'input' ] }
        ],
        dateRange: [
          // { type: 'string', pattern: /^\d{4}-[0,1]{1}\d{1}-[0,1,2,3]{1}\d{1}$/, message: 'Invalid date format (YYYY-MM-DD)', trigger: [ 'change', 'input' ] }
        ],
        orderReference: [
          { type: 'string', min: 4, message: 'Type at least 4 characters to search.', trigger: [ 'change', 'input' ] }
        ]
      }
    }
  },
  computed: {
    hasSearchParams () {
      return this.localForm.dateRange.checkOutDate || this.localForm.dateRange.checkInDate || this.localForm.orderReference || this.localForm.guestName || this.localForm.clients.length || this.localForm.orderStatuses.length || this.localForm.agentReference || this.localForm.hotelName
    }
  },
  created () {
    this.$store.dispatch('admin/clientList').then(
      ({ outlets, meta }) => {
        this.agentOptions = outlets.items
      })
  },
  methods: {
    getAgentByUid (uid) {
      return this.agentOptions.find(agent => agent.appCode === uid)
    },
    getDateRangeLabel (val) {
      if (!val) return this.dateRageOptions[0].label
      const dateRangeOption = this.dateRageOptions.find(o => o.value === val)
      return dateRangeOption ? dateRangeOption.label : ''
    },
    statusFilterChange (val) {
      const tmp = [...val]
      if (tmp.pop() === undefined) {
        this.localForm.orderStatuses = val.filter((v) => v === undefined)
      } else {
        this.localForm.orderStatuses = val.filter((v) => v !== undefined)
      }
      this.delaySearch()
    },
    clear () {
      this.localForm.dateRangeType = 'checkin-date'
      this.localForm.dateRange = {
        checkInDate: '',
        checkOutDate: ''
      }
      this.localForm.orderReference = ''
      this.localForm.clients = []
      this.localForm.hotelName = ''
      this.localForm.guestName = ''
      this.localForm.orderStatuses = []
      this.localForm.agentReference = ''

      this.handleSearch()
    },
    handleSearch () {
      this.$refs.searchBookingsForm.validate().then((valid) => {
        if (valid) {
          this.$emit('submitted', this.localForm)
        }
      })
    },
    delaySearch: debounce(function () {
      this.handleSearch()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
  .search-bookings-form {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -10px;
    margin-bottom: -10px;

    .columns {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -10px;

      &:last-child {
        margin-bottom: -10px;
      }
    }

    .column {
      padding: 10px 8px;
    }

    .el-select {
      width: 100%;
    }
  }
</style>

<style lang="scss">
.clientTag {
  .el-select__tags {
    .el-tag {
      align-items: center;
      display: flex;
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
