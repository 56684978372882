<template>
  <div>
    <div
      id="order-list"
      class="section p-l-0 p-r-0 admin-submenu-section">
      <div class="container is-fluid">
        <h1 class="is-size-3 has-text-primary has-text-weight-bold m-b-4x">
          Orders Management
        </h1>
        <SearchOrdersForm
          ref="searchBookingsForm"
          :is-fetching="isFetching"
          style="max-width: 1180px;padding-bottom: 24px;"
          @submitted="handleSearch" />
      </div>
    </div>
    <div class="section p-l-0 p-r-0">
      <div class="container is-fluid">
        <div
          class="columns"
          style="margin-top: 10px;">
          <div class="column is-12 has-text-right">
            <el-button
              type="primary"
              @click="exportModalActive=true">
              Export to excel
            </el-button>
            <el-button
              class="button-secondary"
              @click="openConfigureColumnModal">
              Configure columns
            </el-button>
          </div>
        </div>
        <el-table
          ref="singleTable"
          :data="rows"
          stripe
          style="width: 100%"
          class="orders-table"
          @row-click="handleSelected"
          @sort-change="handleSortChanged">
          <el-table-column
            v-for="(col,idx) in activeColumns"
            :key="col.value+idx"
            :label="col.label"
            :property="col.prop"
            :sortable="'custom'"
            min-width="200">
            <template slot-scope="scope">
              <span v-if="!col.html">{{ cellFormatter(scope.row, col) }}</span>
              <span
                v-if="col.html"
                v-html="cellFormatter(scope.row, col)" />
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          :total-items="summary ? summary.total : 0"
          :page="localForm.page"
          :page-size="localForm.limit"
          :pager-count="5"
          top-element="#order-list"
          @change="handlePageChange" />
      </div>
    </div>

    <!-- EXPORT MODAL -->
    <PpModal v-model="exportModalActive">
      <PpCard class="box has-border is-flex">
        <div class="export-modal-content">
          <div class="m-b-5x is-size-4 has-text-secondary has-text-weight-bold">
            {{ $t('views.MyBookings.Confirm to Export') }}
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ dateRangeLabel }}
              </div>
              <div class="has-text-weight-bold">
                {{ `${filters.dateRange.checkInDate} - ${filters.dateRange.checkOutDate}` }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                Primary guest
              </div>
              <div class="has-text-weight-bold">
                {{ filters.guestName ||'-' }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                Booking ID
              </div>
              <div class="has-text-weight-bold">
                {{ filters.orderReference ||'-' }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.agentName.placeholder') }}
              </div>
              <div class="has-text-weight-bold">
                {{ agents }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.hotelName.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.hotelName ||'-' }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.status.placeholder') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.orderStatuses && filters.orderStatuses.length > 0 ? filters.orderStatuses.join(', ') : 'All' }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.agentReference.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.agentReference ||'-' }}
              </div>
            </div>
          </div>
          <div
            style="margin-top: 60px;"
            class="has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="exportModalActive=false">
              {{ $t('views.MyBookings.Cancel') }}
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 155px;"
              @click="handleExport">
              {{ $t('views.MyBookings.Export') }}
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>

    <PpModal
      v-model="columnConfigurationModalActive"
      class="column-configuration-modal">
      <PpCard class="box has-border is-flex">
        <div class="column-configuration-modal-content">
          <h3 class="is-size-4 has-text-secondary has-text-weight-bold m-b-2x">
            Column configurations
          </h3>
          <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
            Select columns you want to see
          </h4>
          <ul class="col-select-list">
            <li
              v-for="(c, idx) in configColumns"
              :key="idx">
              <div class="col-item">
                <el-checkbox v-model="c.active">
                  <b class="is-block">{{ c.label }}</b>
                  <span class="is-block">{{ c.desc }}</span>
                </el-checkbox>
              </div>
            </li>
          </ul>
          <hr style="margin: 15px 0!important;">
          <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
            Drag to rearrange the order
          </h4>
          <p>* New column will be appended to the above sample table, scroll to the right to view it</p>
          <draggable
            v-model="configColumns"
            class="column-sort-list m-b-6x">
            <div
              v-for="c in configColumns"
              :key="c.value">
              <div
                v-if="c.active"
                class="column-sort-item">
                <div class="active-item">
                  <span class="column-name">{{ c.label }}</span>
                  <span
                    class="column-remove-btn"
                    @click="handleRemoveColumn(c)"><i class="ap-icon-close" /> </span>
                </div>
              </div>
            </div>
          </draggable>
          <div class="has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleCancel">
              Cancel
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 150px;"
              @click="handleSave">
              Save
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import dates from '../../plugins/dates'
import orderColumns from '@/store/modules/orderColumns'

import SearchOrdersForm from '@/views/admin/SearchOrdersForm'
import Pagination from '@/components/Pagination'
import draggable from 'vuedraggable'
import { download } from '@/plugins/util'

const asyncData = async ({ params }) => {
  try {
    // to indicate superadmin
    params.superAdmin = true
    await store.dispatch('order/retrieveOrderList', params)
  } catch (err) {
    if (err.meta) store.commit('admin/SET_META', err.meta)
    store.commit('SET_GLOBAL_MODAL', {
      show: true,
      content: err.message || (err.error && err.error.message) || ''
    })
  }
}

export default {
  name: 'OrdersManagement',
  components: {
    SearchOrdersForm,
    Pagination,
    draggable
  },
  filters: {
    orderStatus (v) {
      let _html
      switch (v) {
      case 'PENDING':
        _html = '<span class="has-text-grey-light">Pending</span>'
        break
      case 'PAYMENT_PENDING':
        _html = '<span class="has-text-grey-light">Payment in Progress</span>'
        break
      case 'FAILURE':
        _html = '<span class="has-text-danger">Failure</span>'
        break
      case 'REFUND_IN_PROGRESS':
        _html = '<span class="has-text-grey-light">Refund In-Progress</span>'
        break
      case 'REFUND_FAILURE':
        _html = '<span class="has-text-danger">Refund Failure</span>'
        break
      case 'PROCESSING':
        _html = '<span class="has-text-grey-light">Processing</span>'
        break
      case 'CONFIRMED':
        _html = '<span class="has-text-success">Confirmed</span>'
        break
      case 'CANCELLED':
        _html = '<span class="has-text-danger">Cancelled</span>'
        break
      case 'ON_HOLD':
        _html = '<span class="has-text-warning">On Hold</span>'
        break
      case 'AWAITING_CONFIRMATION':
        _html = '<span class="has-text-warning">Awaiting Confirmation</span>'
        break
      default:
        _html = ''
        break
      }
      return _html
    },
    agentEarningStatus (v) {
      let _html
      switch (v) {
      case 'PAID':
        _html = '<span class="has-text-success">Paid</span>'
        break
      case 'UNPAID':
        _html = '<span class="has-text-danger">Unpaid</span>'
        break
      case 'CANCELLED':
        _html = '<span class="has-text-danger">Cancelled</span>'
        break
      default:
        _html = ''
        break
      }
      return _html
    }
  },
  data () {
    return {
      exportModalActive: false,
      columnConfigurationModalActive: false,
      configColumns: [],
      localForm: {
        dateRangeType: '',
        dateRange: {
          checkInDate: '',
          checkOutDate: ''
        },
        hotelName: '',
        orderReference: '',
        agentReference: '',
        guestName: '',
        clients: [],
        orderStatuses: [],
        sort: '',
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    filters () {
      return {
        dateRangeType: this.localForm.dateRangeType,
        dateRange: this.localForm.dateRange,
        orderReference: this.localForm.orderReference,
        agentReference: this.localForm.agentReference,
        guestName: this.localForm.guestName,
        clients: this.localForm.clients,
        orderStatuses: this.localForm.orderStatuses,
        hotelName: this.localForm.hotelName
      }
    },
    agents () {
      if (this.filters.clients.length) {
        let agents = this.filters.clients.map(uid => {
          let agent = this.$refs.searchBookingsForm.getAgentByUid(uid)
          return agent.companyName
        })
        return agents.join(', ')
      }
      return '-'
    },
    dateRangeLabel () {
      return this.filters.dateRangeType ? this.$refs.searchBookingsForm.getDateRangeLabel(this.filters.dateRangeType) : this.$t('views.CheckoutFormSummary.check-in-date')
    },
    ...mapState('order', {
      summary: state => state.orders.summary,
      rows: state => state.orders.rows,
      cols: state => state.orders.cols,
      sort: state => state.orders.sort,
      isFetching: state => state.isFetching,
      isContinuePulling: state => state.orderDetails.isContinuePulling
      // filters: state => state.filters
    }),
    activeColumns () {
      const activeColumns = []
      const inactiveColumns = []
      this.cols.map((col) => {
        const c = orderColumns.findByKey(col)
        if (c) {
          activeColumns.push(c)
        } else {
          inactiveColumns.push(col)
        }
      })
      const keys = this.cols.filter((c) => inactiveColumns.indexOf(c) < 0)
      store.dispatch('order/setOrdersCols', keys)
      return activeColumns.filter(_ => _)
    }
  },
  async created () {
    store.dispatch('order/loadOrdersCols')
    this.configColumns = orderColumns.list
    await asyncData({
      params: {
        limit: 10,
        page: 1
      }
    })
  },
  methods: {
    handleSortChanged ({ column, prop, order }) {
      if (prop === '') return
      const orderType = {
        ascending: 'ASC',
        descending: 'DESC'
      }
      this.localForm.sort = prop == null ? '' : `"${prop}",${orderType[order] ? orderType[order] : 'ASC'}`
      this.fetchHistory(1, this.localForm.limit, this.sanitizeQuery(this.localForm))
    },
    handleSelected (row) {
      this.$router.push({
        name: 'admin/orders-management-detail',
        params: { orderReference: row.order.orderReference }
      })
    },
    handleCancel () {
      this.columnConfigurationModalActive = false
    },
    handleSave () {
      this.columnConfigurationModalActive = false
      const keys = []
      this.configColumns.map((col) => {
        if (col.active) {
          keys.push(col.key)
        }
      })
      store.dispatch('order/setOrdersCols', keys)
    },
    handleRemoveColumn (c) {
      c.active = false
    },
    handlePageChange ({ page, limit }) {
      this.localForm.page = page
      this.localForm.limit = limit
      this.fetchHistory(this.localForm.page, this.localForm.limit, this.sanitizeQuery(this.localForm))
    },
    async fetchHistory (page, limit, params) {
      await asyncData({
        params: {
          ...params,
          limit,
          page
        }
      })
    },
    sanitizeQuery (query) {
      const dateRange = dates.fullDayRange([query.dateRange.checkInDate, query.dateRange.checkOutDate]).map((e) => {
        if (query.dateRangeType === 'booking-date') {
          const d = new Date()
          let offset = d.getTimezoneOffset() // Get offset depending on browser timezone
          e = e.add(offset, 'm').toDate() // Adjust timezone to fit server createAt time
        }
        return e.toJSON()
      }).join('|')
      const sanitized = {
        ...(query.dateRangeType === 'checkin-date' && (dateRange !== '|' && dateRange !== '') ? { checkInDateRange: dateRange } : undefined),
        ...(query.dateRangeType === 'checkout-date' && (dateRange !== '|' && dateRange !== '') ? { checkoutDateRange: dateRange } : undefined),
        ...(query.dateRangeType === 'booking-date' && (dateRange !== '|' && dateRange !== '') ? { bookingDateRange: dateRange } : undefined),
        ...(query.guestName ? { orderPerson: query.guestName } : undefined),
        ...(query.orderReference ? { orderReference: query.orderReference } : undefined),
        ...(query.hotelName ? { propertyName: query.hotelName } : undefined),
        ...(query.agentReference ? { agentReference: query.agentReference } : undefined),
        ...(Array.isArray(query.clients) && query.clients.length > 0
          ? {
            applicationCodes: query.clients.join(',')
          } : undefined),
        ...(Array.isArray(query.orderStatuses) && query.orderStatuses.length > 0
          ? {
            orderStatuses: query.orderStatuses.join(',')
          } : undefined),
        ...(query.sort ? { sort: query.sort } : undefined),
        ...(query.limit ? { limit: query.limit } : undefined),
        ...(query.page ? { page: query.page } : undefined)
      }
      return sanitized
    },
    handleSearch (query) {
      // store.commit('order/SET_ORDER_FILTERS', query)
      this.localForm = { ...this.localForm, ...query, page: 1 }
      this.fetchHistory(this.localForm.page, this.localForm.limit, this.sanitizeQuery(this.localForm))
    },
    handleExport () {
      store.dispatch('order/exportXLS', {
        ...this.sanitizeQuery(this.filters),
        cols: this.cols.join(','),
        superAdmin: true
      }).then(({
        data,
        headers
      }) => {
        this.exportModalActive = false
        download(data, headers)
      }).catch((err) => {
        if (err.meta) store.commit('admin/SET_META', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    cellFormatter (row, val) {
      if (!val.formatter) return
      return val.formatter(row, this.$root.$options.filters, this.$options.filters)
    },
    openConfigureColumnModal () {
      this.columnConfigurationModalActive = true
      this.configColumns.map((col) => {
        if (this.cols.includes(col.key)) col.active = true
      })
    }
  },
  metaInfo () {
    return {
      title: 'Orders Management'
    }
  }
}
</script>

<style lang="scss">
  @import "../../styles/bulma-variables";

  .column-configuration-modal .pp-modal--content {
    max-width: 1250px;
  }

  .col-select-list {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }

    .el-checkbox {
      white-space: initial;
    }

    .el-checkbox__label {
      padding-left: 20px;
    }

    .el-checkbox__input {
      position: absolute;
      top: 5px;
      left: 0px;
    }

    li {
      margin-bottom: 15px;
      box-sizing: border-box;
      @include tablet {
        flex: 0 0 20%;
        max-width: 20%;
        .col-item {
          padding-right: 5px;
        }
      }
    }
  }

  .column-sort-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .column-sort-item {
      display: block;
      background-color: $primary;
      color: #fff;
      cursor: pointer;
      text-align: center;
      height: 100%;
      white-space: nowrap;
      padding: 10px 15px;
      margin: 2px;
      position: relative;

      .column-remove-btn {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid $danger-color;
        color: $danger-color;
        background-color: $white;
        display: none;
        position: absolute;
        right: -4px;
        top: -4px;
        z-index: 10;
      }

      &:hover {
        .column-remove-btn {
          display: block;
        }
      }

    }
  }

  .export-modal-content {
    min-width: 600px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    @include mobile {
      min-width: 100%;
    }
  }
</style>
