<template>
  <div>
    <PpModal v-model="open">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            class="columns is-multiline"
            label-position="top">
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Update comission status
              </h3>
            </div>
            <el-form-item
              class="column is-12"
              prop="agentEarningStatus">
              <el-radio
                v-model="form.agentEarningStatus"
                label="PAID">
                Paid
              </el-radio>
              <el-radio
                v-model="form.agentEarningStatus"
                label="UNPAID">
                Unpaid
              </el-radio>
            </el-form-item>
            <el-form-item
              class="column is-12"
              label="Remarks"
              prop="agentEarningStatusRemarks">
              <el-input
                v-model="form.agentEarningStatusRemarks"
                placeholder="remarks" />
            </el-form-item>
            <div
              v-if="error"
              class="column is-12">
              <el-alert
                :description="error"
                :closable="false"
                type="error" />
            </div>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                Cancel
              </el-button>
              <el-button
                :loading="isSubmitting"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSave">
                Update
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
export default {
  name: 'AgentEarningModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    agentEarningStatus: {
      type: String,
      default: ''
    },
    agentEarningStatusRemarks: {
      type: String,
      default: ''
    },
    orderReference: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      error: null,
      isSubmitting: false,
      form: {
        agentEarningStatus: this.agentEarningStatus,
        agentEarningStatusRemarks: this.agentEarningStatusRemarks
      },
      rules: {}
    }
  },
  watch: {
    agentEarningStatus: {
      handler (nV) {
        this.form.agentEarningStatus = nV
      }
    },
    agentEarningStatusRemarks: {
      handler (nV) {
        this.form.agentEarningStatusRemarks = nV
      }
    }
  },
  methods: {
    handleSave () {
      this.$refs.form.validate((valid) => {
        this.error = null
        this.isSubmitting = true
        if (valid) {
          this.$store.dispatch('admin/updateOrder', { orderReference: this.orderReference, ...this.form }).then((resp) => {
            this.isSubmitting = false
            this.$emit('confirm', {
              ...this.form
            })
            this.$emit('update:open', false)
          }).catch((err) => {
            this.isSubmitting = false
            this.error = err.message || (err.error && err.error.message) || ''
          })
        }
      })
    },
    handleCancel () {
      this.error = null
      this.$refs.form.resetFields()
      this.$emit('update:open', false)
    }
  }
}
</script>

<style scoped>

</style>
