<template>
  <div>
    <div class="section  admin-submenu-section">
      <div class="container">
        <div class="m-b">
          <router-link
            to="/admin/orders-management"
            class="has-text-secondary has-text-weight-bold">
            <IconBase
              width="14"
              height="12"
              icon-name="back"
              class="m-r-2x">
              <IconBack />
            </IconBase>
            <span>{{ $t('views.admin.Back') }}</span>
          </router-link>
        </div>
        <div class="columns">
          <div class="column">
            <div
              class="card-booking-ref is-size-3 is-inline-block"
              style="vertical-align: middle;">
              {{ order.orderReference }}
            </div>
            <OrderStatus
              :status="order.status.code"
              class="is-inline-block"
              style="vertical-align: middle;" />
          </div>
          <div class="column is-narrow">
            <el-button
              :disabled="disableSendMail"
              class="button-secondary button-155"
              @click.prevent="sendEmailModalActive = true">
              {{ $t('views.book.Send to Email') }}
            </el-button>
            <el-button
              :disabled="disableVoucher"
              class="button-secondary button-155"
              @click.prevent="() => downloadVoucherOrInvoice('voucher')">
              {{ $t('views.MyBookings.Get Voucher') }}
            </el-button>
            <el-button
              :disabled="disableVoucher"
              type="primary"
              class="button-155"
              @click.prevent="() => downloadVoucherOrInvoice('receipt')">
              {{ $t('views.MyBookings.Get Invoice') }}
            </el-button>
            <el-button
              v-if="order.status.code === 'AWAITING_CONFIRMATION'"
              class="button-155"
              type="danger"
              @click.prevent="confirmOrder">
              {{ $t('views.book.confirm-order') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div
          v-if="order"
          style="padding-top: 10px;">
          <div class="columns m-b-0">
            <div class="column has-text-right p-b-0">
              <OrderDetailCopier
                v-if="order&&property&&property.location&&booking&&contactPerson"
                :order-reference="order.orderReference"
                :property-name="property.name"
                :address="property.location.address"
                :room-count="booking.roomCount"
                :nights="booking.nights"
                :check-in="booking.checkInDate"
                :check-out="booking.checkOutDate"
                :lead-guest="contactPerson.fullName"
                :adult-count="booking.adultCount"
                :children-count="booking.childrenCount" />
            </div>
          </div>
          <div class="columns m-b-0">
            <div class="column is-half p-b-0">
              Company: <router-link
                v-if="clientLink"
                :to="clientLink"
                class="is-size-5 has-text-secondary has-text-weight-bold">
                {{ agent.companyName }}
              </router-link>
            </div>
            <div class="column is-half p-b-0">
              Hotel Confirmation ID: <span
                class="is-size-5 has-text-primary has-text-weight-bold">
                {{ booking.hotelConfirmationId ? booking.hotelConfirmationId : '-' }}</span>
            </div>
          </div>
          <div class="columns m-b-0">
            <div class="column is-half p-b-0">
              {{ $t('views.MyBookings.Booked by') }}
              <router-link
                v-if="createdByLink"
                :to="createdByLink"
                class="is-size-5 has-text-secondary has-text-weight-bold">
                {{ order.createdBy.name }}
              </router-link>
              <span
                v-else
                class="is-size-5 has-text-primary has-text-weight-bold">-</span>
            </div>
            <div class="column is-half p-b-0">
              {{ $t('views.MyBookings.Cancelled by') }}
              <router-link
                v-if="cancelledByLink"
                :to="cancelledByLink"
                class="is-size-5 has-text-secondary has-text-weight-bold">
                {{ booking.cancelledBy.name }}
              </router-link>
              <span
                v-else
                class="is-size-5 has-text-primary has-text-weight-bold">-</span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half p-b-0">
              {{ $t('views.MyBookings.RTX Booking ID') }}
              <span class="is-size-5 has-text-primary has-text-weight-bold">
                {{ booking.v3BookingId }}
              </span>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>{{ $t('views.MyBookings.Booking date') }}</th>
                <th>{{ $t('views.MyBookings.Cancelled date') }}</th>
                <th>{{ $t('views.MyBookings.Travel date') }}</th>
                <th>{{ $t('views.MyBookings.Guest Count') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    class="is-size-5 has-text-primary has-text-weight-bold"
                    style="max-width: 250px;">
                    {{ property.name }}
                  </div>
                  <div class="m-b">
                    <PropertyAddress
                      :location="property.location"
                      map-link
                      style="max-width: 250px;" />
                  </div>
                  <div class="is-size-7">
                    {{ propertyPhone }}
                  </div>
                </td>
                <td>
                  {{ booking.createdAt | date('MMM DD YYYY HH:mm') }}
                </td>
                <td v-if="booking.cancelledAt">
                  {{ booking.cancelledAt | date('MMM DD YYYY HH:mm') }}
                </td>
                <td v-else>
                  -
                </td>
                <td> {{ booking.checkInDate|date('MMM DD YYYY') }} - {{ booking.checkOutDate|date('MMM DD YYYY') }}</td>
                <td>
                  <span class="is-block">{{ `${booking.adultCount} ${$t('views.CheckoutFormSummary.adults')}, ` }}</span>
                  <span class="is-block">{{ `${booking.childrenCount} ${$t('views.CheckoutFormSummary.children')}` }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Room') }}</th>
                <th>{{ $t('views.MyBookings.Guest Name') }}</th>
                <th>{{ $t('views.MyBookings.Contact No') }}</th>
                <th>{{ $t('views.MyBookings.Email') }}</th>
                <th>{{ $t('views.MyBookings.Nationality') }}</th>
                <th>{{ $t('views.MyBookings.Remarks') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(guest, idx) in booking.roomLeadGuests"
                :key="idx">
                <td>
                  <div style="max-width: 250px;">
                    <span class="is-block has-text-weight-bold has-text-primary">{{ $t('views.MyBookings.Room') }} {{ idx + 1 }}</span>
                    <span class="is-block">{{ booking.supplierDescription | truncate }}</span>
                    <span
                      v-if="$isFoodCodeValid(booking.foodCode)"
                      class="is-block">
                      {{ booking.foodCode | foodCode }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ `${guest.firstName} ${guest.lastName}` }}
                </td>

                <td v-if="guest.telCountryCode && guest.contactNo">
                  {{ `${guest.telCountryCode} ${guest.contactNo}` }}
                </td>
                <td v-else>
                  -
                </td>

                <td v-if="guest.email">
                  {{ guest.email }}
                </td>
                <td v-else>
                  -
                </td>

                <td>
                  {{ getCountryName(guest.nationality) }}
                </td>
                <td>
                  <span
                    class="is-block"
                    style="max-width: 200px;">{{ guest.remarks }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Cancellation policy -->
          <CancellationPolicies
            v-if="booking.metadata.cancellationPolicy && booking.metadata.cancellationPolicy.cancellationPolicies.length > 0"
            :cancellation-policies="booking.metadata.cancellationPolicy.cancellationPolicies"
            :free-cancellation-before="booking.freeCancellationBefore"
            :total-amount="order.agentActualChargeAmount"
            :booking-created="booking.createdAt"
            :show-cancel-btn="showCancelBtn"
            @cancelBooking="handleCancelBooking" />

          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Price') }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ $t('views.MyBookings.Total amount') }}
                </td>
                <td class="has-text-right">
                  {{ order.initialSellRate | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td>
                  Markup
                </td>
                <td class="has-text-right">
                  {{ order.agentMarkupAmount | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td>
                  Commission
                </td>
                <td class="has-text-right">
                  {{ order.agentFinalAdjustmentAmount | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType === 'pay_at_hotel'">
                <td
                  class="has-border"
                  style="padding:0 15px !important">
                  Pay at hotel commission
                </td>
                <td class="has-border has-text-right">
                  {{ order.agentProfit | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel' && order.additionalFeeAmount.value > 0">
                <td>
                  {{ $t('views.book.Additional fee for low transaction') }}
                </td>
                <td class="has-text-right">
                  {{ order.additionalFeeAmount | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td class="has-border">
                  {{ $t('views.book.Transaction fee') }}
                </td>
                <td class="has-border has-text-right">
                  {{ order.finalAdjustmentAmount | currencyObj }}
                </td>
              </tr>
              <tr>
                <td class="has-border">
                  {{ $t('views.MyBookings.Total charged amount') }}
                </td>
                <td class="has-border has-text-right">
                  <div>
                    {{ order.agentActualChargeAmount | currencyObj }}
                    <PriceAudit
                      v-if="order.auditLogs"
                      :raw="order.auditLogs"
                      :use-icon="true"
                      class="is-inline" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Comission <span
                    v-if="order.agentEarningStatus"
                    :class="'UNPAID'===order.agentEarningStatus ?'has-background-danger':'has-background-success'"
                    class="card-booking-status"> {{ order.agentEarningStatus }} </span>
                </td>
                <td class="has-text-right">
                  {{ order.agentProfit | currencyObj }}
                  <div
                    v-if="order.status.code==='CONFIRMED'"
                    style="padding-top: 1rem;">
                    <el-button
                      class="button-secondary"
                      @click="agentEarningModalActive=true">
                      Commission Paid?
                    </el-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table m-b-0">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Payment status') }}</th>
                <th>{{ $t('views.MyBookings.Payment gateway') }}</th>
                <th>{{ $t('views.MyBookings.Captured') }}</th>
                <th>{{ $t('views.MyBookings.Paid') }}</th>
                <th>{{ $t('views.MyBookings.Refunded') }}</th>
                <th>Source</th>
                <th>Last 4</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ ucFirst(payment.status) }}
                </td>
                <td>
                  {{ ucFirst(payment.paymentGateway) }}
                </td>
                <td>
                  {{ getPaidAndCapturedStatus ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
                <td>
                  {{ getPaidAndCapturedStatus ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
                <td>
                  {{ payment.status === 'refunded' ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
                <td>
                  {{ payment.metadata ? ucFirst(payment.metadata.paymentMethod) : '-' }}
                </td>
                <td>
                  {{ payment.metadata ? payment.metadata.cardSummary : '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <tbody>
              <tr>
                <td class="has-border">
                  Charged amount
                </td>
                <td class="has-border has-text-right">
                  {{ totalChargedAmount| currencyObj }}
                </td>
              </tr>
              <tr>
                <td>
                  Refunded amount
                </td>
                <td class="has-text-right">
                  {{ {value: payment.amountRefunded / 100, currency: payment.currency} | currencyObj }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ addTransferLabel }}</td>
              </tr>
            </tbody>
          </table>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('views.MyBookings.Additional Note') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click="additionalNoteActive = true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit />
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table">
            <div class="column is-6">
              <div class="td">
                <div>{{ order.metadata.additionalNote || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-5">
              <div class="th">
                {{ $t('views.admin.admin-note-internal') }}
              </div>
            </div>
            <div class="column is-5">
              <div class="th">
                {{ $t('views.admin.admin-note-external') }}
              </div>
            </div>
            <div class="column is-2 has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click="adminNoteActive = true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit />
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table">
            <div class="column is-5">
              <div class="td">
                <div>{{ order.metadata.adminNoteInternal || '-' }}</div>
              </div>
            </div>
            <div class="column">
              <div class="td">
                <div>{{ order.metadata.adminNoteExternal || '-' }}</div>
              </div>
            </div>
          </div>
          <OrderHistory
            v-if="order && order.orderReference"
            :order-reference="order.orderReference"
            :history="order.history"
            @clicked-insert-log="insertLog" />
        </div>
      </div>
    </div>

    <SendConfirmationEmail
      :open.sync="sendEmailModalActive"
      :email="contactPerson?contactPerson.email:''"
      :order-reference="order?order.orderReference:''"
      :status="order?order.status.code:''"
      :super-admin="true" />
    <AgentEarningModal
      :open.sync="agentEarningModalActive"
      :order-reference="order?order.orderReference:''"
      :agent-earning-status="order?order.agentEarningStatus:''"
      :remarks="order?order.metadata.agentEarningRemarks:''"
      @confirm="afterAgentEarningUpdate" />

    <PpModal v-model="additionalNoteActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="additionalNoteForm"
            :model="additionalNoteForm"
            :rules="additionalNoteRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyBookings.additional-note-modal.title') }}
              </h3>
              <div>
                {{ $t('views.MyBookings.additional-note-modal.description') }}
              </div>
            </div>
            <el-form-item
              :label="$t('views.MyBookings.additional-note-modal.additional-note')"
              class="column is-12"
              prop="additionalNote">
              <el-input
                v-model="additionalNoteForm.additionalNote"
                :rows="3"
                type="textarea" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="additionalNoteActive = false; additionalNoteForm.additionalNote = undefined">
                {{ $t('views.MyBookings.Cancel') }}
              </el-button>
              <el-button
                :disabled="!additionalNoteForm.additionalNote"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSubmitNote(false)">
                {{ $t('views.MyBookings.Save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>

    <PpModal v-model="adminNoteActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="adminNoteForm"
            :model="adminNoteForm"
            :rules="additionalNoteRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.admin.edit-admin-note') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.admin.admin-note-internal')"
              class="column is-12"
              prop="adminNoteInternal">
              <el-input
                v-model="adminNoteForm.adminNoteInternal"
                :rows="3"
                type="textarea" />
            </el-form-item>
            <el-form-item
              :label="$t('views.admin.admin-note-external')"
              class="column is-12"
              prop="adminNoteExternal">
              <el-input
                v-model="adminNoteForm.adminNoteExternal"
                :rows="3"
                type="textarea" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="adminNoteActive = false; adminNoteForm.adminNoteInternal = undefined; adminNoteForm.adminNoteExternal = undefined">
                {{ $t('views.MyBookings.Cancel') }}
              </el-button>
              <el-button
                :disabled="!adminNoteForm.adminNoteInternal && !adminNoteForm.adminNoteExternal"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSubmitNote(true)">
                {{ $t('views.MyBookings.Save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>

    <PpModal v-model="insertLogActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="insertLogForm"
            :model="insertLogForm"
            :rules="insertLogRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyBookings.insert-log-modal.title') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.MyBookings.insert-log-modal.message')"
              class="column is-12"
              prop="message">
              <el-input
                v-model="insertLogForm.insertLog"
                :rows="3"
                :placeholder="$t('views.MyBookings.insert-log-modal.placeholder')"
                type="textarea" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="insertLogActive = false; insertLogForm.insertLog = undefined">
                {{ $t('views.MyBookings.Cancel') }}
              </el-button>
              <el-button
                :disabled="!insertLogForm.insertLog"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleInsertLog">
                {{ $t('views.MyBookings.Save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

import PriceAudit from '@/components/PriceAudit'
import IconBack from '@/components/icons/IconBack'
import OrderStatus from '@/components/OrderStatus'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import PropertyAddress from '@/components/PropertyAddress'
import SendConfirmationEmail from '@/views/share/SendConfirmationEmail'
import dates from '@/plugins/dates'
import AgentEarningModal from '@/views/admin/components/AgentEarningModal'
import OrderDetailCopier from '@/components/OrderDetailCopier'
import OrderHistory from '@/views/admin/components/OrderHistory'
import IconEdit from '@/components/icons/IconEdit'
import CancellationPolicies from '@/components/CancellationPolicies'

import {
  retrieveAdmin
} from '@/service/auth'

const asyncData = async ({ params }) => {
  try {
    await store.dispatch('order/retrieveOrderByReference', { orderReference: params.orderReference, superAdmin: true })
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    store.commit('SET_GLOBAL_MODAL', {
      show: true,
      content: err.message || (err.error && err.error.message) || ''
    })
  }
}

const requestTransferOptions = {
  ADD_AIRPORT_TRANSFER: 'Yes! Add (airport) transfer to this booking',
  ADD_TOUR_TICKET: 'Yes! Add a tour/activity or tickets to this booking',
  ONLY_ACCOMMODATION: 'No, I only need the accommodation'
}

export default {
  name: 'OrdersManagementDetail',
  components: {
    OrderHistory,
    OrderDetailCopier,
    AgentEarningModal,
    PriceAudit,
    OrderStatus,
    IconBack,
    IconEdit,
    PropertyAddress,
    SendConfirmationEmail,
    CancellationPolicies
  },
  async beforeRouteEnter (to, from, next) {
    try {
      if (!to.params.orderReference) {
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: 'Booking not Found'
        })
      }
      await asyncData({ params: to.params })
      next()
    } catch (err) {
      next()
    }
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      agentEarningModalActive: false,
      sendEmailModalActive: false,
      additionalNoteActive: false,
      insertLogActive: false,
      adminNoteActive: false,
      adminNoteForm: {
        adminNoteInternal: undefined,
        adminNoteExternal: undefined
      },
      additionalNoteForm: {
        additionalNote: undefined
      },
      sendEmailForm: {
        email: ''
      },
      insertLogForm: {
        insertLog: undefined
      },
      additionalNoteRules: {
        additionalNote: {
          max: 500,
          trigger: [
            'change',
            'blur'
          ]
        },
        adminNoteInternal: {
          max: 500,
          trigger: [
            'change',
            'blur'
          ]
        },
        adminNoteExternal: {
          max: 500,
          trigger: [
            'change',
            'blur'
          ]
        }
      },
      insertLogRules: {
        insertLog: {
          required: true
        }
      }
    }
  },
  computed: {
    ...mapState('order', {
      agent: state => state.orderDetails.agent,
      booking: state => state.orderDetails.booking,
      property: state => state.orderDetails.property,
      order: state => state.orderDetails.order,
      contactPerson: state => state.orderDetails.contactPerson || state.orderDetails.order.contactPerson,
      payment: state => state.orderDetails.payment,
      isFetching: state => state.isFetching,
      isContinuePulling: state => state.orderDetails.isContinuePulling
    }),
    breadCrumb () {
      // FIXME
      return [
        {
          label: this.$t('views.breadcrumb.Manage Bookings'),
          replace: true,
          route: { name: 'my-bookings' }
        }
      ]
    },
    showCancelBtn () {
      if (!this.booking) return false
      return ['CONFIRMED', 'ON_HOLD'].includes(this.order.status.code) && dates.dateDiff(new Date(), this.booking.checkInDate) > -1
    },
    clientLink () {
      return this.order && this.order.appCode ? {
        name: 'admin/client-management/overview',
        params: { appCode: this.order.appCode }
      } : null
    },
    createdByLink () {
      return this.order && this.order.createdBy && this.order.createdBy.uid ? {
        name: 'admin/user-management/overview',
        params: { uid: this.order.createdBy.uid }
      } : null
    },
    cancelledByLink () {
      return this.booking && this.booking.cancelledBy ? {
        name: 'admin/user-management/overview',
        params: { uid: this.booking.cancelledBy.uid }
      } : null
    },
    statusCode () {
      return this.order.status && this.order.status.code
    },
    disableVoucher () {
      return this.statusCode !== 'AWAITING_CONFIRMATION' && this.statusCode !== 'CONFIRMED'
    },
    disableSendMail () {
      return this.statusCode !== 'AWAITING_CONFIRMATION' && this.statusCode !== 'CONFIRMED' && this.statusCode !== 'CANCELLED'
    },
    addTransferLabel () {
      let label = ''
      if (this.order.metadata && this.order.metadata.requestTransfer && this.order.metadata.requestTransfer.length > 0) {
        const onlyAccommodationExists = this.order.metadata.requestTransfer.find(e => e === 'ONLY_ACCOMMODATION')
        if (onlyAccommodationExists) {
          label = requestTransferOptions[this.order.metadata.requestTransfer[0]]
        } else {
          if (this.order.metadata.requestTransfer.length > 0) {
            this.order.metadata.requestTransfer.forEach((key, idx) => {
              label += `${requestTransferOptions[key]}`
              label += idx === this.order.metadata.requestTransfer.length - 1 ? '' : ', '
            })
          }
        }
      }
      return label || '-'
    },
    getPaidAndCapturedStatus () {
      if (this.payment && this.payment.paymentGateway === 'pay_at_hotel') {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const checkoutDate = new Date(this.booking.checkOutDate)
        return today >= checkoutDate && this.order.status.code === 'CONFIRMED'
      } else {
        return this.payment.metadata.paid ? this.payment.metadata.paid : this.payment.status === 'succeeded'
      }
    },
    totalChargedAmount () {
      const payment = this.payment || {}
      const chargedAmount = {
        currency: payment.currency,
        value: payment.amount / 100
      }

      if (!this.getPaidAndCapturedStatus) {
        chargedAmount.value = 0
      } else if (this.payment.status === 'refunded') {
        chargedAmount.value = (payment.amount - payment.amountRefunded) / 100
      }

      return chargedAmount
    },
    propertyPhone () {
      const contacts = this.property.contacts || {}
      return contacts.phone || ''
    }
  },
  methods: {
    insertLog () {
      this.insertLogActive = true
    },
    getCountryName (code) {
      return getCountryByAlpha2(code, 'en-US').lb
    },
    handleSendEmail () {
      this.$refs.sendEmailForm.validate(async (valid) => {
        if (valid) {
          await this.$store.dispatch('order/sendEmail', {
            email: this.sendEmailForm.email,
            orderReference: this.$route.params.orderReference,
            superAdmin: true
          })
          this.sendEmailModalActive = false
          return true
        } else {
          return false
        }
      })
    },
    handleCancelSendMail () {
      this.$refs.sendEmailForm.resetFields()
      this.sendEmailModalActive = false
    },
    downloadVoucherOrInvoice (type) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      store.dispatch('order/getVoucherOrInvoice', {
        orderReference: this.order.orderReference,
        type,
        superAdmin: true
      }).then(({ data, headers }) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        const newBlob = new Blob([data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          this.handleDownloadModalClosed()
          return
        }
        const tmp = window.URL.createObjectURL(newBlob)
        const contentDisposition = headers['content-disposition']
        const filename = contentDisposition.replace('attachment; filename=', '')
        let link = document.createElement('a')
        link.href = tmp
        link.download = filename
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(tmp)
        }, 100)
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('admin/SET_META', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || this.$t('app.subtitle'),
          okBtn: true
        })
      })
    },
    handleCancelBooking () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      store.dispatch('order/cancelOrder', { orderReference: this.order.orderReference, superAdmin: true }).then(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$alert(this.$t('views.MyBookings.success-message'), this.$t('views.MyBookings.success-title'), {
          confirmButtonClass: 'button-secondary button-100',
          confirmButtonText: this.$t('app.ok'),
          customClass: 'success-message-box'
        }).then(() => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER')
          const cancelInterval = setInterval(async () => {
            await asyncData({ params: this.params })
            if (['REFUND_IN_PROGRESS', 'CANCELLED'].indexOf(this.statusCode) > -1) {
              clearInterval(cancelInterval)
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
              if (this.statusCode === 'REFUND_IN_PROGRESS') {
                store.commit('SET_GLOBAL_MODAL', {
                  show: true,
                  okBtn: true,
                  title: this.$t('views.MyBookings.error.refundInProgress.title'),
                  content: this.$t('views.MyBookings.error.refundInProgress.content')
                })
              }
            }
          }, 6000) // 3 seconds is not enough. Wait 6 seconds
        })
      }).catch(async (err) => {
        await asyncData({ params: this.$route.params })
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          okBtn: true,
          title: this.$t('views.MyBookings.error.cancelInProgress.title'),
          content: this.$t('views.MyBookings.error.cancelInProgress.content')
        })
      }).catch(async (err) => {
        await asyncData({ params: this.$route.params })
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          okBtn: true,
          title: this.$t('views.MyBookings.error.cancelInProgress.title'),
          content: this.$t('views.MyBookings.error.cancelInProgress.content')
        })
      })
    },
    ucFirst (string) {
      if (!string) return
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async afterAgentEarningUpdate () {
      await asyncData({ params: this.$route.params })
    },
    async confirmOrder () {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER')
        await this.$store.dispatch('admin/confirmOrder', this.order.orderReference)
        await asyncData({ params: this.$route.params })
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      } catch (err) {
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      }
    },
    async handleSubmitNote (isAdminNote = false) {
      try {
        const form = isAdminNote ? this.$refs.adminNoteForm : this.$refs.additionalNoteForm
        form.validate(async (valid) => {
          if (valid) {
            this.$store.commit('SHOW_FULLSCREEN_LOADER')
            const payload = { orderReference: this.order.orderReference }
            if (isAdminNote) {
              this.adminNoteActive = false
              payload.adminNoteInternal = this.adminNoteForm.adminNoteInternal
              payload.adminNoteExternal = this.adminNoteForm.adminNoteExternal
            } else {
              this.additionalNoteActive = false
              payload.additionalNote = this.additionalNoteForm.additionalNote
            }
            await this.$store.dispatch('admin/updateOrderMetadata', payload)
            await asyncData({ params: this.$route.params })
            this.additionalNoteForm.additionalNote = undefined
            this.adminNoteForm = {
              adminNoteInternal: undefined,
              adminNoteExternal: undefined
            }
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
          }
        })
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: this.$t(`error.${err.error && err.error.code}`) || err.message || (err.error && err.error.message) || ''
        })
      }
    },
    async handleInsertLog () {
      try {
        const form = this.$refs.insertLogForm
        form.validate(async (valid) => {
          if (valid) {
            this.$store.commit('SHOW_FULLSCREEN_LOADER')
            const payload = { orderReference: this.order.orderReference }
            this.insertLogActive = false
            payload.insertLog = this.insertLogForm.insertLog
            payload.adminUser = {
              uid: retrieveAdmin('uid'),
              displayName: retrieveAdmin('displayName')
            }

            await this.$store.dispatch('admin/updateOrderMetadata', payload)
            await asyncData({ params: this.$route.params })
            this.insertLogForm.insertLog = undefined
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
          }
        })
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: this.$t(`error.${err.error && err.error.code}`) || err.message || (err.error && err.error.message) || ''
        })
      }
    }
  },
  metaInfo () {
    return {
      title: 'Order Detail'
    }
  }
}
</script>
